import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpResponse } from '../interfaces/http-response.interface';
import {
  SignupPhaseOne,
  SignupPhaseTwo,
  SignupPhaseThree,
  SignupPhaseFive,
  LoginRequest,
  resendOTPRequest,
  resetPassword,
  Colab,
  createTeam,
  invitation,
  forceUpdate,
  teamwiseMemberList,
  colleagueList,
  allUsers,
  colleagueInvitation,
  updateRole,
  getMyFiles,
  addUpdateColabOrgConfig,
  sendEmailVerificationLink,
} from '../interfaces/signup.interface';
import * as moment from 'moment';
import 'moment-timezone';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { JwtService } from './jwt.service';
import {
  getMyAttendence,
  getTeamDays,
  getAttendenceReport,
  getTeamDaysInternal,
} from '../interfaces/attendence-report';

@Injectable({
  providedIn: 'root',
})
export class AuthHttpService {
  private endpoint: string = environment.endpoint;
  private endpoint2: string = environment.endpoint2;
  private endpointFileViewer: string = environment.fileviewerPath;
  constructor(private http: HttpClient, private jwt: JwtService) {}

  signupPhaseOne(data: SignupPhaseOne) {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/CustomerLead/LeadSignUp',
      data
    );
  }

  signupPhaseTwo(data: SignupPhaseTwo) {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/CustomerLead/VerifyOtp',
      data
    );
  }

  resetPassword(data: resetPassword) {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/forgotPassword',
      data
    );
  }

  signupPhaseThree(data: SignupPhaseThree) {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/OrgAccount/SignUp',
      data
    );
  }

  login(data: LoginRequest) {
    return this.http.post<HttpResponse>(this.endpoint + 'api/User/Login', data);
  }

  getUserDetails() {
    return this.http.get<HttpResponse>(this.endpoint + 'api/getUserDetails');
  }

  resendOTP(data: resendOTPRequest) {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/CustomerLead/ResendOtp',
      data
    );
  }

  getVerticals() {
    return this.http.get<HttpResponse>(
      this.endpoint + 'api/Vertical/GetVerticals'
    );
  }
  getColabs() {
    return this.http.get<HttpResponse>(
      this.endpoint + 'api/Module/GetAllModules'
    );
  }
  saveColabs(data: Colab) {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/OrgAccount/FinalStepsAddInOrg',
      data
    );
  }
  getCentralized(id: any) {
    let data: any = {
      ID: id,
    };
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/getCentralized',
      data
    );
  }

  getAddons() {
    return this.http.get<HttpResponse>(this.endpoint + 'api/getAddons');
  }

  signupPhaseFive(data: SignupPhaseFive) {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/registerFive',
      data
    );
  }

  postInvitation(data: invitation) {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/Hierarchy/AddUpdateHierarchialNode',
      data
    );
  }

  forceUpdate(data: forceUpdate) {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/User/AcceptInvitations',
      data
    );
  }

  getColleagueList(data: colleagueList) {
    if (
      localStorage.getItem('org_name') != '-----' &&
      localStorage.getItem('org_name') != null &&
      localStorage.getItem('invite-string') == null
    ) {
      return this.http.post<HttpResponse>(
        this.endpoint + 'api/User/GetColleaugueList',
        data
      );
    }
    return null;
  }
  getPunch() {
    if (
      localStorage.getItem('org_name') != '-----' &&
      localStorage.getItem('org_name') != null &&
      localStorage.getItem('invite-string') == null
    ) {
      return this.http.get<HttpResponse>(
        this.endpoint2 + 'attendance/Punch/GetMemberLatestPunch'
      );
    }
    return null;
  }

  postColleagueInvitation(data: colleagueInvitation) {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/User/InviteMembers',
      data
    );
  }
  private accessTokenSubject = new BehaviorSubject<string>(null);
  public accessToken$ = this.accessTokenSubject.asObservable();

  refreshToken() {
    let input: any = {};
    input.token = this.jwt.getTokenFromLocalStorage();
    input.refresh_token = this.jwt.getRefreshTokenFromLocalStorage();
    return this.http.post(this.endpoint + 'api/User/RefreshToken', input).pipe(
      tap((data: any) => {
        this.jwt.setToken(data.data.token);
        this.jwt.setRefreshToken(data.data.refresh_token);
        this.accessTokenSubject.next(data.data.token);
      })
    );
  }

  refreshTokenManual() {
    let input: any = {};
    input.token = this.jwt.getTokenFromLocalStorage();
    input.refresh_token = this.jwt.getRefreshTokenFromLocalStorage();
    return this.http.post(this.endpoint + 'api/User/RefreshToken', input).pipe(
      tap((data: any) => {
        this.jwt.setToken(data.data.token);
        this.jwt.setRefreshToken(data.data.refresh_token);
        this.scheduleRefresh();
      })
    );
  }
  deleteProfilePicture(data: any) {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/User/DeleteProfilePicture',
      data
    );
  }

  changePassword(data: any) {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/User/SendOtpForForgotPassword',
      data
    );
  }

  sendLink(data) {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/CellularMessaging/SendMessage',
      data
    );
  }
  getMyAttendence(data: any) {
    return this.http.post<HttpResponse>(
      this.endpoint2 + 'attendance/Attendance/GetMyAttendance',
      data
    );
  }
  getAttendenceReport(data: getAttendenceReport) {
    let timezone = moment.tz.guess();
    const header = new HttpHeaders().set('TimeZone', timezone);
    return this.http.post<HttpResponse>(
      this.endpoint2 + 'attendance/Attendance/GetMyAttendanceReport ',
      data,
      { headers: header }
    );
  }
  verifyInvCode(data: any) {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/CustomerLead/VerifyPassCode',
      data
    );
  }

  cancelInvitation(data: colleagueInvitation) {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/User/CancelInvitations',
      data
    );
  }
  getTeamDays(data: getTeamDays) {
    let timezone = moment.tz.guess();
    const header = new HttpHeaders().set('TimeZone', timezone);
    return this.http.post<HttpResponse>(
      this.endpoint2 + 'attendance/Attendance/GetAttendanceSummary',
      data,
      { headers: header }
    );
  }
  loginDataRefresh() {
    let data = '';
    return this.http.get<HttpResponse>(
      this.endpoint + 'api/User/FindUserByToken'
    );
  }
  FindUserByTokenV1() {
    let data = '';
    return this.http.get<HttpResponse>(
      this.endpoint + 'api/User/FindUserByToken_V1'
    );
  }

  loginDataRefreshOrgSetup() {
    let data = '';
    return this.http.get<HttpResponse>(
      this.endpoint + 'api/User/FindUserByToken'
    );
  }

  getAttendenceReport1(data: any) {
    let timezone = moment.tz.guess();
    const header = new HttpHeaders().set('TimeZone', timezone);
    return this.http.post<HttpResponse>(
      this.endpoint2 + 'attendance/Attendance/GetHierarchyWiseTimeSheetReport',
      data,
      { headers: header }
    );
  }
  getAttendenceReport1AllTeam(data: any) {
    let timezone = moment.tz.guess();
    const header = new HttpHeaders().set('TimeZone', timezone);
    return this.http.post<HttpResponse>(
      this.endpoint2 + 'attendance/Attendance/GetTimeSheetReport',
      data,
      { headers: header }
    );
  }

  rwmoveActiveMembers(data: any) {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/User/RevokeUsersFromOrg',
      data
    );
  }

  changeOrgName(data: any) {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/OrgAccount/UpdateOrgDetails',
      data
    );
  }

  postExcel(data: any) {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/User/UploadBulkUserInvitationFile',
      data
    );
  }

  getexcel() {
    return this.http.get<HttpResponse>(
      this.endpoint + 'api/Attachment/GetSampleFileForBulkInviteUser'
    );
  }

  timer: any;
  scheduleRefresh() {
    const expiryTime = this.jwt.getExpiryTime();
    const currentTime = new Date().getTime();
    let remainingTime = expiryTime - currentTime;
    if (remainingTime <= 60000) {
      this.refreshTokenManual();
    } else {
      this.timer = setTimeout(() => {
        this.scheduleRefresh();
      }, remainingTime - 60000);
    }
  }

  getTeamDaysInternal(data: getTeamDaysInternal) {
    return this.http.post<HttpResponse>(
      this.endpoint2 +
        'attendance/Attendance/GetBulkUserAttendanceSummaryDurationWise ',
      data
    );
  }
  getTeamReportInternal(data: any) {
    let timezone = moment.tz.guess();
    const header = new HttpHeaders().set('TimeZone', timezone);
    return this.http.post<HttpResponse>(
      this.endpoint2 +
        'attendance/Attendance/GetBulkUserAttendanceSummaryDurationWiseReport',
      data,
      { headers: header }
    );
  }
  getTeamReportInternalLg(data: any) {
    let timezone = moment.tz.guess();
    const header = new HttpHeaders().set('TimeZone', timezone);
    return this.http.post<HttpResponse>(
      this.endpoint2 + 'attendance/Attendance/GetTimepunchLogReport',
      data,
      { headers: header }
    );
  }
  getAttributes(data: any) {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/CustomAttribute/GetAllCustomAttributes',
      data
    );
  }
  saveAttributes(data: any) {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/CustomAttribute/CreateCustomAtrributeMappingForOrg',
      data
    );
  }
  getDynamicAttributes(data: any) {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/CustomAttribute/GetAllColumnHeaders',
      data
    );
  }
  getProcessStatus(data: any) {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/User/GetProcessStatus',
      data
    );
  }
  getUploadProcess(data: any) {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/User/GetUploadProcessProgress',
      data
    );
  }
  getOnGoingProcess(data: any) {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/User/GetOnGoingUploadProcess',
      data
    );
  }

  getAllTeamReportDownloadStatus(data: any) {
    return this.http.post<HttpResponse>(
      this.endpoint2 + 'attendance/Attendance/GetReportProcessStatus',
      data
    );
  }

  getOnGoingReportProcess(data: any) {
    return this.http.post<HttpResponse>(
      this.endpoint2 + 'attendance/Attendance/GetOnGoingUploadProcess',
      data
    );
  }

  searchUsers(data: any) {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/User/SearchUser',
      data
    );
  }

  setWatcherOfHierarchy(data: any) {
    return this.http.post(
      this.endpoint + 'api/Hierarchy/SetWatcherOfHierarchy',
      data
    );
  }
  getWatcherListForUser(data: any) {
    return this.http.post(
      this.endpoint + 'api/Hierarchy/GetWatcherListForUser',
      data
    );
  }
  selectedUserData(data: any) {
    return this.http.post(this.endpoint + 'api/User/GetAllUsers', data);
  }

  searchTeams(data: any) {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/Hierarchy/SearchHierarchy',
      data
    );
  }

  selectedSendbirdUserData(data: any) {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/User/GetUserByCommunicationDetails',
      data
    );
  }

  getRoles(data: any) {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/Role/GetRadiumRole',
      data
    );
  }
  updateRole(data: updateRole) {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/Role/ChangeUserRole',
      data
    );
  }
  logoutTokenExpiry(data: any) {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/User/LogOutTokenExpiry',
      {}
    );
  }

  getPermissionMatrix(data?: { features: string[] }) {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/PermissionMatrix/GetActionMasterDetails',
      data
    );
  }

  createMultipleWatcher(data: any) {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/Hierarchy/SetWatcherOfHierarchy',
      data
    );
  }

  getAttachmentOfTeams(data: any) {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/attachment/GetAttachmentList',
      data
    );
  }
  getAttachmentOfMembers(data: any) {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/attachment/GetAttachmentListForIndividual',
      data
    );
  }
  postLink(msg: any, id, type, uId, fileGuid) {
    //user_ids
    let uIds: any = uId;
    const formData: FormData = new FormData();
    let linkArray: any = [];
	msg = msg.reduce(function(a,b){if(a.indexOf(b)<0)a.push(b);return a;},[]);
    msg?.forEach((element, index) => {
      linkArray[index] = { fileGuid: fileGuid, file_url: element };
    });
    if (msg == null) {
      linkArray[0] = { fileGuid: fileGuid, file_url: '' };
    }
	console.log(linkArray);
    formData.append('links', JSON.stringify(linkArray));

    if (type == 'individual') {
      formData.append('user_ids', id);
    } else {
      formData.append('hierarchy_ids', uIds);
    }
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/Attachment/AddLinks',
      formData,
      {
        reportProgress: true,
        responseType: 'json',
      }
    );
  }
  getLinkData(link: any) {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/CustomAttribute/GetHyperlinkData',
      link
    );
  }
  changeColleaguVisibility(data: any) {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/Hierarchy/ChangeColleagueVisibility',
      data
    );
  }
  sendFiles(data: any) {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/Attachment/ForwardUrl',
      data
    );
  }

  sendAppLink(data: any) {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/CellularMessaging/SendMessage',
      data
    );
  }
  getMyFiles(data: getMyFiles) {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/attachment/GetMyFiles',
      data
    );
  }

  addFileMapViewer(data: any) {
    return this.http.post<HttpResponse>(
      this.endpointFileViewer + 'api/add-map',
      data
    );
  }

  removeFileMapViewer(data: any) {
    return this.http.post<HttpResponse>(
      this.endpointFileViewer + 'api/remove-map',
      data
    );
  }
  setAnnouncer(data: any) {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/Hierarchy/SetAnnouncers',
      data
    );
  }
  revokeAnnouncer(data: any) {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/Hierarchy/RevokePermissionOfAnnouncers',
      data
    );
  }
  DeleteUploadFile(data: any) {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/attachment/DeleteUploadFile',
      data
    );
  }
  UpdateSendfilestatus(data: any) {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/attachment/UpdateSendbirdfilestatus',
      data
    );
  }
  resendOtp(data: any) {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/CustomerLead/ResendOtp',
      data
    );
  }
  changeTimeFormat(data: addUpdateColabOrgConfig) {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/OrgAccount/AddUpdateColabOrgConfig',
      data
    );
  }
  getUserAttendence(data: any) {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/User/GetUserLatestCheckInOutDetails',
      data
    );
  }
  sendEmailVerificationLink(data: sendEmailVerificationLink) {
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/CustomerLead/SendEmailVerificationlink',
      data
    );
  }
  emailVerify(data: any) {
    let timezone = moment.tz.guess();
    const header = new HttpHeaders().set('TimeZone', timezone);
    return this.http.post<HttpResponse>(
      this.endpoint + 'api/CustomerLead/ConfirmleadEmailverification',
      data,
      { headers: header }
    );
  }
}
